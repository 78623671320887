import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Icon, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, StackItem, SocialMedia, Stack, Menu } from "@quarkly/components";
import * as Components from "components";
import { MdEmail, MdLanguage } from "react-icons/md";
import { FaSteamSquare } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Ternox Games Contact
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202021-05-07%2002-16-13-646.png?v=2021-05-09T23:29:44.135Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.EmbedHTML />
		<Components.Menuu />
		<Section background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Runner%202023-05-22%2004-55-13-844.png?v=2023-06-12T13:56:19.924Z) center/cover" padding="40px 0 40px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					About me
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					My name is Ruslan Salikov.
					<br />
					I’m a solo indie developer from Ukraine. I’ve been creating games on Gamemaker since high school. I have a passion for retro aesthetics.
				</Text>
			</Box>
		</Section>
		<Section
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/mayclover_meadow_night_example.png?v=2020-10-03T23:33:05.039Z)"
			margin="0 0px 0px 0px"
			color="--light"
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			position="relative"
			sm-height="auto"
		>
			<Override slot="SectionContent" sm-height="auto" />
			<Stack>
				<StackItem md-width="100%" width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Icon
						size="48px"
						category="md"
						icon={MdEmail}
						height="48px"
						width="48px"
						position="absolute"
					/>
					<Box
						position="relative"
						padding="0 0 0 64px"
						sm-padding="64px 0 0 0"
						margin="0x 0 0 0"
						max-width="360px"
					>
						<Text as="h4" margin="6px 0" font="--lead">
							Email me
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							<Link text-decoration="none" hover-text-decoration="underline" color="--light" href="mailto:me@ternoxgames.com">
								me@ternoxgames.com
							</Link>
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							<Link text-decoration="none" hover-text-decoration="underline" color="--light" href="mailto:ternox1@gmail.com">
								ternox1@gmail.com
							</Link>
						</Text>
					</Box>
				</StackItem>
				<StackItem md-width="100%" width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Icon
						category="md"
						icon={MdLanguage}
						height="48px"
						width="48px"
						position="absolute"
						size="48px"
					/>
					<Box
						sm-padding="64px 0 0 0"
						margin="0x 0 0 0"
						max-width="360px"
						position="relative"
						padding="0 0 0 64px"
					>
						<Text
							as="p"
							margin="6px 0"
							font="--lead"
							text-align="left"
							display="inline"
						>
							<Link color="--light" text-align="center" text-decoration="none" hover-text-decoration="underline">
								Social networks
							</Link>
						</Text>
						<SocialMedia
							text-align="left"
							position="relative"
							display="flex"
							instagram="https://www.instagram.com/taimumari/"
							facebook="https://www.facebook.com/taimumari"
							youtube="https://www.youtube.com/channel/UC1rKs1kvj_f1S7lwpHGZ9TQ/"
							icon-size="32px"
							margin="3px 0px 0px 0px"
							padding="0px 28px 0px 0px"
							twitter="https://twitter.com/taimumari"
							left={0}
						/>
					</Box>
				</StackItem>
			</Stack>
			<Box margin="13px 0px 0px 0px" text-align="left">
				<StackItem md-width="100%" width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Icon
						icon={FaSteamSquare}
						bottom="auto"
						height="50px"
						width="48px"
						position="absolute"
						size="48px"
						left="auto"
						category="fa"
					/>
					<Box
						margin="0x 0 0 0"
						max-width="360px"
						position="relative"
						padding="0 0 0 64px"
						sm-padding="64px 0 0 0"
					>
						<Text display="flex" font="--headline3">
							Follow me on{" \n"}
							<Link href="https://store.steampowered.com/developer/ternox" target="_blank" color="#ffffff">
								Steam
							</Link>
							{"\n\n"}
							<br />
							{"\n\n"}
						</Text>
					</Box>
				</StackItem>
			</Box>
		</Section>
		<Components.Htmlform />
		<Section background-color="--dark" text-align="center" padding="32px 0">
			<Menu
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
				display="flex"
				justify-content="center"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
				<Override slot="link-index">
					Main page
				</Override>
				<Override slot="link-policy">
					Private policy
				</Override>
				<Override slot="link-oldone">
					Legacy
				</Override>
			</Menu>
			<Link
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
				href="mailto:hello@company.com"
			>
				me@ternoxgames.com
			</Link>
		</Section>
	</Theme>;
});